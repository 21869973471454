<template>
  <div style="background: white">
    <!-- banner -->
    <!-- <div class="banner"></div> -->
    <!-- 企业产品与服务 -->
    <div class="service-c">
      <div class="service">
        <div class="title">企业产品与服务</div>
        <div class="subTitle">全场景覆盖，快速高效解决招聘问题</div>
        <div class="serviceList">
          <div class="serviceItem" v-for="(item, index) in list" :key="index">
            <div class="flex1">
              <img :src="item.pic" alt="" />
              <div>
                <div class="serviceTitle">
                  {{ item.title }}
                </div>
                <div class="serviceCont">{{ item.cont }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 部分合作客户 -->
    <div class="coop" v-show="false">
      <div class="title">部分合作客户</div>
      <div class="subTitle">每天为数万家企业提供优质服务</div>
      <div class="coopCont flex1">
        <img src="../../assets/img/coopimg.png" alt="" />
        <div class="coopList flex2">
          <div
            class="coopLogo"
            v-for="(coopItem, coop) in coopList"
            :key="coop"
          >
            <img :src="coopItem.logo" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 部分所获荣誉 -->
    <div class="honor" v-show="false">
      <div class="title">部分合作客户</div>
      <div class="subTitle">每天为数万家企业提供优质服务</div>
      <div class="honorCont flex2">
        <div
          class="honorList"
          v-for="(honorItem, honor) in honorList"
          :key="honor"
        >
          <img :src="honorItem.pic" alt="" />
        </div>
      </div>
    </div>
    <!-- 快速通道 -->
    <div class="honor">
      <div class="title">快速通道</div>
      <div class="subTitle">东阳人才服务快速通道</div>
      <div class="honorConts flex2">
        <div class="honorList" v-for="(honorItem, honor) in KList" :key="honor">
          <img :src="honorItem.pic" @click="tourl(honorItem)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          pic: require("@/assets/img/icon1.png"),
          title: "网络招聘",
          cont: "专业垂直细分网络求职招聘平台,高效精准在线招聘服务,聚集各行业专业技术人才,保障企业招聘效果",
        },
        {
          pic: require("@/assets/img/icon2.png"),
          title: "商务流程外包",
          cont: "企业非核心业务流程外包服务,专注于企业重要业务的发展，提高运作效率及生产力,促进企业人力资源由事务性向战略性的转变",
        },
        {
          pic: require("@/assets/img/icon3.png"),
          title: "招聘会",
          cont: "针对标杆企业多个岗位招聘需求，进行定制化的专场招聘，通过双重简历筛选，高精准匹配人才，进行定向邀约，集中面试。",
        },
        {
          pic: require("@/assets/img/icon4.png"),
          title: "东阳快聘",
          cont: "高效率“限时”招聘服务，致力为企业发展补全人员配置拼图,助力企业快速解决核心人才的引进难题",
        },
        {
          pic: require("@/assets/img/icon5.png"),
          title: "V雇主",
          cont: "聚焦客户需求，快速反应，为客户创造最大价值,为企业提供全产业链人力资源服务",
        },
        {
          pic: require("@/assets/img/icon6.png"),
          title: "三人猎",
          cont: "通过创新的合作模式，严密的运营体系，汇聚各行业志同道合的优秀猎头合伙人，一同为企业提供高效高质服务",
        },
        {
          pic: require("@/assets/img/icon7.png"),
          title: "专业猎头",
          cont: "国内专业人才服务机构,采用国际猎头公司的规范方式运作，拥有一支精通行业人才的高素质寻访队伍，为人才及企业提供优质服务",
        },
        {
          pic: require("@/assets/img/icon8.png"),
          title: "Offer派",
          cont: "国内专业人才服务机构,采用国际猎头公司的规范方式运作，拥有一支精通行业人才的高素质寻访队伍，为人才及企业提供优质服务",
        },
        {
          pic: require("@/assets/img/icon9.png"),
          title: "更多",
          cont: "针对标杆企业多个岗位招聘需求，进行定制化的专场招聘，通过双重简历筛选，高精准匹配人才，进行定向邀约，集中面试。",
        },
      ],
      coopList: [],
      honorList: [
        { pic: require("@/assets/img/honor2.png") },
        { pic: require("@/assets/img/honor1.png") },
        { pic: require("@/assets/img/honor3.png") },
        { pic: require("@/assets/img/honor2.png") },
        { pic: require("@/assets/img/honor1.png") },
        { pic: require("@/assets/img/honor3.png") },
        { pic: require("@/assets/img/honor2.png") },
        { pic: require("@/assets/img/honor1.png") },
        { pic: require("@/assets/img/honor3.png") },
        { pic: require("@/assets/img/honor2.png") },
      ],
      KList: [
        {
          pic: require("@/assets/img/k1.png"),
          url: "/info",
          data: "3711",
          type: 2,
        },
        {
          pic: require("@/assets/img/k2.png"),
          url: "/info",
          data: "3492",
          type: 2,
        },
        {
          pic: require("@/assets/img/k3.png"),
          url: "/info",
          data: "2705",
          type: 2,
        },
        {
          pic: require("@/assets/img/k4.png"),
          url: "/info",
          data: "2325",
          type: 2,
        },
        {
          pic: require("@/assets/img/k5.png"),
          url: "/info",
          data: "1842",
          type: 2,
        },
        {
          pic: require("@/assets/img/k6.png"),
          url: "/info",
          data: "1618",
          type: 2,
        },
      ],
    };
  },
  methods: {
    tourl(item) {
      this.resolvewin(item.url, { id: item.data }, item.type);
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 28px;
  color: #333;
  text-align: center;
}
.subTitle {
  font-size: 14px;
  color: #999;
  text-align: center;
  margin: 10px 0 30px;
}
// banner
.banner {
  width: 100%;
  height: 300px;
  background: url(../../assets/img/service.png) center;
}
.service-c {
  width: 100%;
  background: url(../../assets/img/bimg.jpg) center;
  padding: 20px 0;
}
// 企业产品与服务
.service {
  width: 1200px;
  margin: 1px auto 10px;
  padding-top: 50px;
  .serviceList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .serviceItem {
      background: white;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      font-size: 12px;
      color: #666;
      width: 385px;
      height: 120px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
      img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }
      .serviceTitle {
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;
      }
      .serviceCont {
        width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .serviceItem:hover {
      box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
    }
  }
}
// 部分合作客户
.coop {
  width: 100%;
  height: 480px;
  background: url(../../assets/img/bgIMG.jpg) center;
  padding-top: 45px;
  box-sizing: border-box;
  .coopCont {
    width: 1200px;
    margin: 0 auto;
    img {
      width: 525px;
      margin-right: 15px;
    }
    .coopList {
      width: 660px;
      .coopLogo {
        width: 155px;
        height: 85px;
        overflow: hidden;
        margin: 10px 0;
        img {
          width: 100%;
        }
      }
    }
  }
}
// 部分所获荣誉
.honor {
  width: 100%;
  height: auto;
  // background: url(../../assets/img/honor.png) center top no-repeat;
  padding-top: 45px;
  box-sizing: border-box;
  .title {
    color: #333;
  }
  .subTitle {
    color: #333;
  }
  .honorCont {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 20px;
    box-sizing: border-box;
    background: white;
    margin-bottom: 50px;
    .honorList {
      width: 220px;
      height: 150px;
      overflow: hidden;
      margin-bottom: 20px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
  .honorConts {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 20px 50px 20px;
    box-sizing: border-box;
    background: white;
    margin-bottom: 1px;
    .honorList {
      width: 370px;
      height: 120px;
      overflow: hidden;
      margin-bottom: 20px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
}
</style>